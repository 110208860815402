import {
	buildThresholdList,
} from '../helpers.js';

import { 
	registerFoldOuts
} from '../main.js';

export class LoadOMatic {
	constructor(
		append_container
	) {
		this.reached_end = false;

		this.append_container = document.querySelector( '.loadomatic' );
		this.message = document.querySelector( '.loadomatic__message' );
		this.message.appendChild( document.createElement( 'p' ) );
		//this.message.querySelector( 'p' ).innerHTML = 'Loading more....';
		this.trigger = document.querySelector( '.loadomatic__trigger' );

		this.callbacks = [];

		this.handleTrigger();
	}

	getCurrentPage() {
		return parseInt(
			this.getQueryObject( window.location.search ).page || '1', 10
		);
	}

	resetEnd( hide_button = false ) { // default - button doesn't get hidden immediately until loadMore checks over the data first
		this.reached_end = false;
		if ( hide_button ) {
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
		}
	}

	loadMore( replace_all = false, increment_page = true ) {
		if ( this.reached_end ) {
			return;
		}

		let url;
		if ( increment_page ) {
			url = this.getUrl( this.getCurrentPage() + 1 );
			this.resetEnd( true ); // Hide Load more immediately while content loads
		} else {
			url = this.getUrl();
		}

		// Get the page after the page to be displayed to see if 
		// Load More button needs to be hidden
		const url_params = new URLSearchParams( url.split('?')[1] );
		let page = url_params.get( 'page' );
		if ( !page ) {
			page = 1;
		}
		const next_page = this.getUrl( parseInt( page ) + 1 );

		// Decide whether Load More button should be hidden before results are 
		// displayed for a smoother loading experience
		ajax( {
			type: 'get',
			url: next_page,
			data: {},
			headers: [
				['X-LoadOMatic', 'yes'],
			],
			timeout: 5000,
			onSuccess: ( data, status ) => {
				if ( data.trim() === '' || status === 202 ) {
					this.reached_end = true;
				} else {
					this.reached_end = false;
				}

				if ( increment_page ) {
					this.handleTrigger( 750 ); // Delay showing the trigger when content is loading
				} else {
					this.handleTrigger();
				}

				// Get entries 
				ajax( {
					type: 'get',
					url: url,
					data: {},
					headers: [
						['X-LoadOMatic', 'yes'],
					],
					timeout: 5000,
					onSuccess: ( data, status ) => {
						if ( ! ( data.trim() === '' || ( replace_all === false && status === 202 ) ) ) {
							if ( replace_all ) {
								this.append_container.innerHTML = data;
							} else {
								this.append_container.innerHTML += data;
							}
							for ( let i = 0; i < this.callbacks.length; i++ ) {
								this.callbacks[i]();
							}
							if ( window.history.pushState ) {
								window.history.pushState( null, null, url );
							}
						
							// Fade in listing items
							const items = document.querySelectorAll( '.listing__item--hidden' );
							const options = {
								rootMargin: '-300px 0px -100px 0px',
								threshold: buildThresholdList(),
							};
							const observer = new IntersectionObserver( entries => {
								let count = 0;
								entries.forEach( ( entry, i ) => {
									count++;
									if ( entry.isIntersecting ) {
										const delay = i * 100;
										setTimeout( () => {
											entry.target.classList.remove( 'listing__item--hidden' );
											observer.unobserve( entry.target );
										}, delay );
									}
								});
							}, options );
							if ( items.length ) {
								items.forEach( item => {
									observer.observe( item );
								} );
							}
							registerFoldOuts(); // Register fold outs on newly loaded listing items
						}
					},
					onFailure: this.handleFailure(),
				} );
			},
		} );
	}

	handleTrigger( delay ) {
		this.message.classList.add( 'loadomatic__message--hidden' );
		if ( this.reached_end ) {
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			return;
		} else {
			setTimeout( () => {
				this.trigger.classList.remove( 'loadomatic__trigger--hidden' );
			}, delay );
		}
		setTimeout( () => {
			this.trigger.classList.remove( 'loadomatic__trigger--disabled' );
		}, delay );
	}

	handleFailure() {
		return () => {
			this.message.classList.remove( 'loadomatic__message--hidden' );
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			this.message.querySelector( 'p' ).innerHTML = 'Sorry, there was a problem retrieving content.';
		}
	}

	getQueryObject( query_string ) {
		const query_object = {};
		const pairs = ( query_string[0] === '?' ? query_string.substr( 1 ) : query_string ).split( '&' );
		for ( let i = 0; i < pairs.length; i++ ) {
			const pair = pairs[i].split( '=' );
			if (pair != '' ) {
				let key = decodeURIComponent( pair[0] );
				const value = decodeURIComponent( pair[1] || '' );
				if ( !( query_object[key] instanceof Array ) ) {
					query_object[key] = [];
				}
				query_object[key].push( value );
				continue;
			}
		}
		return query_object;
	}

	getQueryString( query_object ) {
		const query_pieces = [];
		for ( const key in query_object ) {
			if ( !query_object.hasOwnProperty( key ) ) {
				continue;
			}
			if ( query_object[key] instanceof Array ) {
				for ( let i = 0; i < query_object[key].length; i++ ) {
					query_pieces.push( key + ( query_object[key][i] ? '=' + query_object[key][i] : '' ) );
				}
				continue;
			}
			query_pieces.push( key + ( query_object[key] ? '=' + query_object[key] : '' ) );
		}

		if ( query_pieces.length === 0 ) {
			return '';
		}

		return '?' + query_pieces.join( '&' );
	}

	getUrl( page = null ) {
		const query_object = this.getQueryObject( window.location.search );
		//if ( query_object.hasOwnProperty( 'page' ) ) {
		//	query_object.page = intval( query_object.page ) + 1;
		//}
		if ( page !== null ) {
			query_object.page = page;
		}
		const query_string = this.getQueryString( query_object );

		return `${window.location.pathname}${query_string}`;
	}

	addCallback( fx ) {
		this.callbacks.push( fx );
	}
}